.purple-toggle-button-group {
    /* background-color: "#52009b"; Set the background color to purple */
    color: white; /* Set the text color to white or any other color that contrasts well */
    border-color: #52009b;
  }

  .card-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .triangle-topright-red {
    width: 0;
    height: 0;
    border-top: 33px solid red;
    border-left: 35px solid transparent;
    border-radius: 5px;
  }
  .triangle-topright-green {
    width: 0;
    height: 0;
    border-top: 33px solid #31a777;
    border-left: 35px solid transparent;
    border-radius: 3.5px;
  }
  .triangle-topright-amber {
    width: 0;
    height: 0;
    border-top: 33px solid  #f1bf1c;
    border-left: 35px solid transparent;
    border-radius: 3.5px;
  }

 

  .hr-horizontal{
    border-width: 1px;
    /* border-block-color: lightgrey; */
    border-color: lightgrey;
    margin-top: 9px;

  }
  .hr-selectbox{
   border: 1px solid #dbdde8  ;

  }
.card-category{
    height: 38px;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    width: 212px;
    align-items: end;
    margin-bottom: 10px;
    ;
}

  .card-category-name{
    display: flex;
    flex-direction: row;
    margin-bottom: 27px;
    line-height: 0.9;
    margin-top: 18px
  }

  .card-category-span{
    font-size: 14px;
    font-weight: bold;
    /* color: #52009b; */
    color: #402899;
    cursor: pointer;
  }

  .card-category-span:hover {
    text-decoration: underline;
  }

  .card-categories{
    color: "#263445";
    font-size: 14px;
    line-height: 0.9;
  }

  .card-appetite{
    color: "#263445";
    font-size: 13px;
    font-weight: bold;
  }

 

  
  .card{
    background-color: "background.paper";
    box-shadow:  2;
    border-radius:  2;   
    max-width:  331;
    margin:  2;
    height: 278;
    opacity: 1;
    padding: "19px";
  }

  .card-category-num{
    font-size: 28px;
    color: #63BAD5;
    
  }

  .card-category-num-div{
    /* margin-right: 15px; */
    width: 52px;
    height: 46px
  }

  .dialog-title{
    font-size: 21px;
    color: #263445;
  }


  .dialog-appetitie-avoidant{
    width: 96px;
    height: 25px;
    background-color: #FFF0D0;
    border-radius: 16px;
    opacity: 1;
    padding-left: 9px;
    padding-top: 3px;

  }

  .dialog-appetitie-balanced{
    width: 98px;
    height: 25px;
    background-color: #E3D8FC;
    border-radius: 16px;
    opacity: 1;
    padding-left: 9px;
    padding-right: 3px;
    padding-top: 3px;
  }
  .dialog-appetitie-seeking{
    width: 96px;
    height: 25px;
    background-color: #D9F6FF;
    border-radius: 16px;
    opacity: 1;
    padding-left: 9px;
    padding-top: 3px;

  }

  

  .dialog-appetitie-text{
    font-size: 13px;
    letter-spacing: 0.02;
  }
  .card-table-appetitie-text{
    font-size: 12px;
    letter-spacing: 0.02;
    font-weight: 500;
  }

.table-over{
  color: red

}

.table-within{
  color: #666c7b

}

.table-under{
  color: #ebbd16c2

}

/* table shadow */
.css-80pr5n-MuiPaper-root{
  box-shadow: none !important;
}

/* dialog box */
.css-jf8cg3-MuiPaper-root-MuiDialog-paper{
  border-radius: none !important;
}

.menuitem-font{
  font-size: 17px;
}

/* details tab filter arrow icon */
.filter-arrow-icon{
  width: 10px;
height: 5px;
background: #000000 0% 0% no-repeat padding-box;
opacity: 1;
}


/* multi select font */
.css-1cohrqd-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1cohrqd-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1cohrqd-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  font-size: 13px;
}


/* table heading */
.table-head-title{
  font-size: "15px";
  width: 250px;
  cursor: pointer;

  position: relative; /* Needed for positioning pseudo-elements */
  color: black; /* Default text color */
}

/* Style for the arrow ::before pseudo-element */
.table-head-title::after {
  content: "▶"; /* Unicode arrow character (you can customize this) */
  display: inline-block;
  margin-right: 5px; /* Adjust spacing as needed */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition */
}

/* Hover effect for .table-head-title */
.table-head-title:hover {
  color: #402899; /* Text color on hover */
  text-decoration: underline;
}

/* Show the arrow and change its color on hover */
.table-head-title:hover::before {
  opacity: 1; /* Show the arrow on hover */
  color: purple; /* Arrow color on hover */
}

/* ============dialog table title============================= */
.dialog-head-title{
  font-family: 21px;
  cursor: pointer;
}

.dialog-head-title:hover{
  color: #402899;
  text-decoration: underline;
}

/* filter bubble===================================================================== */
.filter-bubble{
  /* width: 197px; */
height: 30px;
/* UI Properties */
border-radius: 16px;
border: 1px solid #402899;
opacity: 1;
display: flex;
align-items: center;
margin-right: 15px;
margin-bottom:10px;
}
.filter-bubble span{
  font-size: 14px;
  color: #402899;
  padding-left: 7px;
  padding-right: 7px;
}



/* ------------ category details page------------------------------------------------------------- */
.influential-factor-titles{
  font-size: 17px;
  color: #7354CB;
  opacity: 1;
  font-weight: 500;
  cursor: pointer;
}

.Influential-div1{
  width: 100%;
  height: 116px;
  background: var(--light-gray-2) 0% 0% no-repeat padding-box;
background: #F7F8FF 0% 0% no-repeat padding-box;
border-radius: 8px;
opacity: 1;
}
.Influential-div2{
  width: 100%;
  height: 116px;
  background: var(--light-gray-2) 0% 0% no-repeat padding-box;
background: #F7F8FF 0% 0% no-repeat padding-box;
border-radius: 8px;
opacity: 1;
}

.influential-factor-num{
  text-align: left;
font-size:  36px ;
letter-spacing: 0.01px;
color: #7354CB;
opacity: 1;
height: 58px;
margin-top: 7px;

}
.influential-factor-num2{
  text-align: left;
font-size:  33px ;
letter-spacing: 0.01px;
color: #7354CB;
opacity: 1;
height: 48px;
margin-top: 7px;

}

.influential-factor-div-text1{
  font-size: 15px;
  letter-spacing: 0.01;
  opacity: 1;
  color: #263445;
  font-weight: 500;
  height: 20px;
}

/* on hover underline */
.Influential-div1:hover .influential-factor-num span {
  text-decoration: underline;
  text-decoration-thickness: 1px;
}
.Influential-div1:hover {
 background-color: #7354CB1A ;
}

.Influential-div2:hover {
  background-color: #7354CB1A ;
 }

 .Influential-div2:hover .influential-factor-num2 span {
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

#specific-stack:hover .influential-factor-num2 span {
  text-decoration: underline;
}

.stack-div:hover .influential-factor-num2 span {
  text-decoration: underline;
  text-decoration-thickness: 1px;
}
.influential-factor-div-text2{
  font-size: 13px;
  letter-spacing: 0.01;
  opacity: 1;
  color: #8090AA;
  /* font-weight: 500; */
}

.influential-factor-div-text3{
  font-size: 13px;
  letter-spacing: 0.01;
  opacity: 1;
  color: #263445;
  /* font-weight: 500; */
  height: 20px;
}

.influential-factor-div-text4{
  font-size: 15px;
  letter-spacing: 0.01;
  opacity: 1;
  color: #263445;
  font-weight: 500;
  height: 36px;
  width: 80px;
  margin-top: 25px;
}


/* slider */
.slider-div{
/* top: 593px; */
/* left: 1045px; */
width: 203px;
height: 20px;
/* UI Properties */
background: transparent linear-gradient(90deg, #AED581 0%, #FFF176 48%, #FF9900 100%) 0% 0% no-repeat padding-box;
/* border-radius: 3px; */
opacity: 1;
}

.slider-div-upper-limit{
  top: 589px;
left: 1046px;
width: 3px;
height: 27px;
/* UI Properties */
border: 1px solid #000000;
opacity: 1;
}

.slider-div-target{
  top: 589px;
left: 1103px;
width: 5px;
height: 29px;
/* UI Properties */
background: var(--white) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #707070;
border-radius: 3px;
opacity: 1;
}

.slider-div-lower-limit{
  top: 589px;
  left: 1195px;
  width: 3px;
  height: 27px;
  border: 1px solid #000000;
  opacity: 1;
}

.slider-div-actual{
  top: 785px;
left: 1169px;
width: 11px;
height: 11px;
transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
background: #000000 0% 0% no-repeat padding-box;
opacity: 1;
}

/* ===========breadcrum title================== */
.breadcrumb-span{
  font-size: 15px;
  color: #402899
}

.breadcrumb-span:hover{
  text-decoration: underline;
}

/* arrow icon */
.css-dmx33s-MuiSvgIcon-root{
  font-size: 33px !important;
}

.css-tk8xv1-MuiButtonBase-root-MuiButton-root .material-icon, .css-tk8xv1-MuiButtonBase-root-MuiButton-root .material-icons-round, .css-tk8xv1-MuiButtonBase-root-MuiButton-root svg {
  font-size: 33px !important;
}

.css-tk8xv1-MuiButtonBase-root-MuiButton-root svg{
  font-size: 33px !important;
}



/* slider track */
.css-1z0wqmk-MuiSlider-track{
  display: none !important;
}

.css-19bvq69-MuiSlider-rail {
  display: none !important;
}


/* slider root */
.css-5gsf8f-MuiSlider-root{
  border-radius: none !important;
}

.css-5mho2d-MuiSlider-thumb{
  border-radius: none !important;
}



/* sub cat hardcoded slider */
/* slider */
.sub-slider-div{
  /* top: 593px; */
  /* left: 1045px; */
  width: 270px;
  height: 40px;
  /* UI Properties */
  background: transparent linear-gradient(90deg, #4CAF50 0%, #FFD600 100%) 0% 0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
  }

  .sub-slider-div2{
    /* top: 593px; */
    /* left: 1045px; */
    width: 270px;
    height: 40px;
    /* UI Properties */
    background: transparent linear-gradient(90deg, #B5C2FF 0%, #8071C1 33%, #5A49A5 66%, #402899 100%) 0% 0% no-repeat padding-box;
    border-radius: 13px;
    opacity: 1;
    }

/* Left Circle (Red) */
.sub-slider-div-red {
  /* left: 10px; Adjust the distance from the left edge */
  background-color: red;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  margin-left: 10px;
}

/* Middle Circle (Blue) */
.sub-slider-div-blue {
 
  background-color: blue;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  margin-left: 20px;
}

.sub-slider-blue {
 
  background-color: blue;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  /* margin-left: 20px; */
  margin-left: 10px;

}

/* Right Circle (Grey) */
.sub-slider-div-grey {
 
  background-color: lightgray;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  margin-left: 89px;
}

.sub-slider-grey {
 
  background-color: lightgray;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  /* margin-left: 89px; */
  margin-left: 10px;

}

.sub-slider-div-green {
 
  background-color: darkgreen;
  border-radius: 15%;
  width: 8px;
  height: 35px;
  margin-left: 72px;
}


.sub-slider-green {
 
  background-color: darkgreen;
  border-radius: 15%;
  width: 8px;
  height: 30px;
  margin-left: 10px;
}

.sub-slider-div-white {
 
  background-color: white;
  border-radius: 15%;
  width: 8px;
  height: 35px;
  margin-left: 72px;
}


/* exposure */
.underline-on-hover:hover {
  text-decoration: underline !important;
}
.Taxonomy-overview {
    background-color:"#ffffff";
    border-radius: 2px;
    padding: 20px;

}

.divider {
    border-left: 5.5px solid #ebedf2;
  }

  .dividerCat {
    border-left: 2.5px solid #8090AA;
    /* display: flex; */
    /* flex-wrap: wrap; */
    height: 100%; /* Set the height to 100% to match the height of the parent Grid item */
  display: block;
  margin-left: "20px";
  }

  .hr-horizontal{
    /* padding-top: 5px; */
    border-top:4px #ebedf2;
    /* border-width: 2px; */
  }


  .span-header
  {
    font-size:"12px";
    color: "#4f5668";
  }

  .span-body
  {
    font-size:"20px";
    
  }

  .tax-header{
    font-size: 20px;
    margin-bottom: "1px";
    font-weight: 500;
    /* line-height: 0; */
  }

  .tax-subheader{
    font-size: 15px;
    font-weight: 200;
    line-height: 0.8;
  }

  .tax-divider{
    width: 100%;
    border-width: "4px";
    border-color: black;
  }


  /* add taxonomy button */

  .add-button{
    background-color: "#52009b";
    width: "24px";
  }


.css-18ywdid-MuiButtonBase-root-MuiButton-root {
  text-transform: none !important;
}

.css-978tm5-MuiButtonBase-root-MuiButton-root{
  text-transform: none !important;
}

.css-1bey4x8-MuiButtonBase-root-MuiButton-root{
  text-transform: none !important;
}

.css-1t5rq4r-MuiButtonBase-root-MuiButton-root{
  text-transform: none !important;
}

.css-vljdu3-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
  background: none !important; 
  stroke: none !important;
}

.css-vljdu3-MuiSvgIcon-root-MuiStepIcon-root.Mui-active{
  background: none !important; 
  stroke: none !important;
}

/* .css-108522e-MuiButtonBase-root-MuiButton-root{
  text-transform: none !important;
}

.css-1mscscp-MuiButtonBase-root-MuiButton-root {
  text-transform: none !important;
} */
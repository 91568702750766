.css-80pr5n-MuiPaper-root {
    width: inherit !important;
    background-color: white !important;
}   

.css-1ag9q10{
    background-color:"#52009b" !important;
}

.css-glbxi5-MuiTableHead-root {
    background-color: white !important;
}
.css-5krg6g-MuiTableContainer-root{
    border-radius: 0;
}

.css-1hhu9xl {
    overflow-x: auto !important;
}

/* .css-1dur2uz-MuiPaper-root-MuiDialog-paper {
    max-width: "700px" !important;
}

.dialog-control{
    width: "600px";
} */